import { Component, ViewEncapsulation } from "@angular/core";
import { CommonService } from "src/app/common.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    templateUrl:'./terms.component.html',
    encapsulation: ViewEncapsulation.None,
   
})

export class TermsComponent{
    check;
    data;
    constructor(private service:CommonService,private sanitizer: DomSanitizer){}
    ngOnInit(){
        this.service.getTerms().subscribe(res=>{
            console.log(res);
            this.check = Object.keys(res).length;
            if(this.check>0){
            this.data = this.sanitizer.bypassSecurityTrustHtml(res[0].terms_conditions);
        }
        })
    }
}