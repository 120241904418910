import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';
import { SearchService } from '../../../../services/search.service';
import { AuthService } from '../../../../services/auth.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit {
    name;
    role : any;
    eventName;
    hide = 'false';
    eventlist:any=[];
    notifications: any[];
    emailVerfied = false;
    messagess: any[] = [];
subscription: Subscription;
    constructor(
      private navService: NavigationService,
      public searchService: SearchService,
      private auth: AuthService,
      private service:CommonService,
      private router:Router
    ) {
      this.notifications = [
        {
          icon: 'i-Speach-Bubble-6',
          title: 'New message',
          badge: '3',
          text: 'James: Hey! are you busy?',
          time: new Date(),
          status: 'primary',
          link: '/chat'
        },
        {
          icon: 'i-Receipt-3',
          title: 'New order received',
          badge: '$4036',
          text: '1 Headphone, 3 iPhone x',
          time: new Date('11/11/2018'),
          status: 'success',
          link: '/tables/full'
        },
        {
          icon: 'i-Empty-Box',
          title: 'Product out of stock',
          text: 'Headphone E67, R98, XL90, Q77',
          time: new Date('11/10/2018'),
          status: 'danger',
          link: '/tables/list'
        },
        {
          icon: 'i-Data-Power',
          title: 'Server up!',
          text: 'Server rebooted successfully',
          time: new Date('11/08/2018'),
          status: 'success',
          link: '/dashboard/v2'
        },
        {
          icon: 'i-Data-Block',
          title: 'Server down!',
          badge: 'Resolved',
          text: 'Region 1: Server crashed!',
          time: new Date('11/06/2018'),
          status: 'danger',
          link: '/dashboard/v3'
        }
      ];




      this.subscription=this.service.getMessageNotifaction().subscribe(messagess => {
        console.log(messagess);
        this.ngOnInit();
      });


    }
  
    ngOnInit() {
      var ab = this.service.localdata();
      this.name = ab[0].username;
      this.role = ab[0].id_user_roles;
      if(this.role != 3){
        this.service.getEmailVerified(ab[0].id_users).subscribe(res=>{
          console.log(res);
          // if(res[0].isVerified == tr)
          this.hide = res[0].isVerified;
        })
        console.log(this.hide);
        // this.service.getMessage().subscribe(message => { this.message = message; });
      }else if(this.role == 4){
        this.hide = 'true';
      }else{
        this.hide = 'true';
      }
      console.log(this.role);
      console.log(this.hide);

      this.service.getEventListSearch().subscribe(res=>{
        console.log(res);
        this.eventlist=res;
      })
    }
  
    toggelSidebar() {
      const state = this.navService.sidebarState;
      if (state.childnavOpen && state.sidenavOpen) {
        return state.childnavOpen = false;
      }
      if (!state.childnavOpen && state.sidenavOpen) {
        return state.sidenavOpen = false;
      }
      if (!state.sidenavOpen && !state.childnavOpen) {
          state.sidenavOpen = true;
          setTimeout(() => {
              state.childnavOpen = true;
          }, 50);
      }
    }
  
    signout() {
      localStorage.clear();
      this.router.navigate(['/demo/sessions/signin']);
    }

    logoClick(){
      if(this.role=='1'){
        this.router.navigate(['/demo/dashboard/v3']);
      }else if(this.role=='2'){
        this.router.navigate(['/demo/dashboard/v2']);
      }else if(this.role=='3'||this.role=='4'){
        this.router.navigate(['/demo/dashboard/v1']);
      }
    }

    Selectevent(val) {
      console.log(val);
      this.eventName=val['event_name']
      var id_event=val['id_event']
      // console.log(ss);
          // send message to subscribers via observable subject
          // this.service.sendMessage('Message from Home Component to App Component!');
          this.service.sendMessage(id_event);
         
      }

}
