import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
//  const express = require('express');
var cors = require('cors');
 //const app = express();

// const https = require('https');


@Injectable()



export class SMSSendService {
    
  constructor(private http:HttpClient) { }
  
   
    sendLoginOTP(mobile,otpNumber){
      
       //app.use(cors());
    
        
          var smsApiUrl = 'https://api.myvaluefirst.com/psms/servlet/psms.JsonEservice';
          let header = new HttpHeaders()
          .set('Content-type', 'application/json')
         .set('Access-Control-Allow-Origin', "http://localhost:4200")
          .set('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS');
          
          var userName ="embassyxmltrn";
          var password="embsy001";
          var senderId ="EMBGRP";
         
          var msgText ="Greetings from Embassy International Riding School.%0D%0A"+ otpNumber +  " is the OTP to log in to your EIRS account. This OTP will be valid for the next 5 mins."
          var  toAddress = "{\"@FROM\": \"" + senderId + "\",\"@TO\": \"" + mobile + "\",\"@SEQ\": \"1\",\"@TAG\": \"EIRS - Login OTP\"}";
      
          var reqBody = "{\"@VER\":\"1.2\",\"USER\":{\"@USERNAME\":\"" + userName + "\",\"@PASSWORD\":\"" + password + "\",\"@UNIXTIMESTAMP\":\"\"},\"DLR\":{ \"@URL\":\"\"},\"SMS\":[{\"@UDH\":\"0\",\"@CODING\":\"1\",\"@TEXT\":\"" + msgText + "\",\"@PROPERTY\":\"0\",\"@ID\":\"1\",\"ADDRESS\":[" + toAddress + "]}]}";
       
          var sdf = reqBody.replace(/^'(.*)'$/, '$1');
          return this.http.post(smsApiUrl,reqBody, { headers : header});
      }



      WelcomeAfterregistration(mobile,otpNumber){
        const smsApiUrl = 'https://api.myvaluefirst.com/psms/servlet/psms.JsonEservice';
        let header = new HttpHeaders()
        .set('Content-type', 'application/json')
        .set('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS');
        
        var userName ="embassyxmltrn";
        var password="embsy001";
        var senderId ="EMBGRP";
       
        var msgText ="Greetings from Embassy International Riding School.%0D%0A"+ otpNumber +  " is the OTP to log in to your EIRS account. This OTP will be valid for the next 5 mins."
        var  toAddress = "{\"@FROM\": \"" + senderId + "\",\"@TO\": \"" + mobile + "\",\"@SEQ\": \"1\",\"@TAG\": \"EIRS - Login OTP\"}";
    
        var reqBody = "{\"@VER\":\"1.2\",\"USER\":{\"@USERNAME\":\"" + userName + "\",\"@PASSWORD\":\"" + password + "\",\"@UNIXTIMESTAMP\":\"\"},\"DLR\":{ \"@URL\":\"\"},\"SMS\":[{\"@UDH\":\"0\",\"@CODING\":\"1\",\"@TEXT\":\"" + msgText + "\",\"@PROPERTY\":\"0\",\"@ID\":\"1\",\"ADDRESS\":[" + toAddress + "]}]}";
        var sdf = reqBody.replace(/[']+/g, '')
     
        return this.http.post(smsApiUrl,reqBody, { headers: header});
    }
   
    
}

// export function sendLoginOTP(mobileNumber,otpNumber){
//     const smsApiUrl = 'https://api.myvaluefirst.com/psms/servlet/psms.JsonEservice';
//     let header = new HttpHeaders({ 'Content-Type': 'application/json' });
//     var userName ="embassyxmltrn";
//     var password="embsy001";
//     var senderId ="EMBGRP";
//     var msgText ="Greetings from Embassy International Riding School.%0D%0A"+ otpNumber +  " is the OTP to log in to your EIRS account. This OTP will be valid for the next 5 mins."
//     var  toAddress = "{\"@FROM\": \"" + senderId + "\",\"@TO\": \"" + mobileNumber + "\",\"@SEQ\": \"1\",\"@TAG\": \"EIRS - Login OTP\"},";

//     var reqBody = "{\"@VER\":\"1.2\",\"USER\":{\"@USERNAME\":\"" + userName + "\",\"@PASSWORD\":\"" + password + "\",\"@UNIXTIMESTAMP\":\"\"},\"DLR\":{ \"@URL\":\"\"},\"SMS\":[{\"@UDH\":\"0\",\"@CODING\":\"1\",\"@TEXT\":\"" + msgText + "\",\"@PROPERTY\":\"0\",\"@ID\":\"1\",\"ADDRESS\":[" + toAddress + "]}]}";
 
 
//     return this.httper.post(smsApiUrl,reqBody, { headers: header });
// }


// export class SMSGatwayprocess {

//   private _options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
 
  

  
// callSendLoginOTP(mobile,otp){
//     console.log(mobile);
//     var mobileNumber=mobile;
//     var otpNumber=otp;
  
//    sendLoginOTP(mobileNumber,otpNumber)
//   // //  .subscribe(res=>{
//   // //   const smsApiUrl = 'https://api.myvaluefirst.com/psms/servlet/psms.JsonEservice';
//   // //   console.log(res);
//   //   //do next logic base on response
//   // })
//   }

//   // return this.http.post(smsApiUrl,reqBody);
        





 
// }

 
