import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { single } from 'rxjs/operators';
import { SignupComponent } from '../sessions/signup/signup.component';
import { TermsComponent } from './terms/terms.component';
import { EventPublishComponent } from './event-published/event-published.component';

const routes: Routes = [
    {
        path: '404',
        component: NotFoundComponent
    },
    {
        path: 'signup',
        component: SignupComponent
    },
    {
        path: 'eventpublish',
        component: EventPublishComponent
        
    }
    ,{
        path: 'termsandconditions',
        component: TermsComponent
    }
    
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class OthersRoutingModule { }
