import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    idvalue;
    constructor() {
    }

    defaultMenu1: IMenuItem[] = [];

    defaultMenu2: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'link',
            icon: 'i-Bar-Chart',
            state: '/demo/dashboard/v1'
          
        },
        {
            name: 'Event',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'link',
            icon: 'i-Post-Sign-2-ways',
            state: '/demo/user/viewCalender'
        },
        {
            name: 'Club',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'link',
            icon: 'i-Business-Mens',
            state: '/demo/clubCo-ordinator/club-list'
        },
        {
            name: 'Rider',
            type: 'link',
            icon: 'i-Add-User',
            state: '/demo/rider/viewrider'
        },
        {
            name: 'Horse',
            type: 'link',
            icon: 'i-Computer-Secure',
            state: '/demo/horse/horseview'
        },
        {
            name: 'Financial',
            type: 'link',
            icon: 'i-Financial',
            state: '/demo/financial'
        },
        {
            name: 'Users',
            type: 'link',
            icon: 'i-Administrator',
            state: '/demo/user/adduser'
        },
        {
            name: 'Settings',
            type: 'link',
            icon: 'i-Gear',
            state: '/demo/termsAndConditions'
        }
    
    ];
    defaultMenu4: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'link',
            icon: 'i-Bar-Chart',
            state: '/demo/dashboard/v1'
          
        },
        {
            name: 'Event',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'link',
            icon: 'i-Post-Sign-2-ways',
            state: '/demo/user/viewCalender'
        },
          {
            name: 'Club',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'link',
            icon: 'i-Business-Mens',
            state: '/demo/clubCo-ordinator/club-list'
        },

        {
            name: 'Rider',
            type: 'link',
            icon: 'i-Add-User',
            state: '/demo/rider/viewrider'
        },
        {
            name: 'Horse',
            type: 'link',
            icon: 'i-Computer-Secure',
            state: '/demo/horse/horseview'
        },
        {
            name: 'Financial',
            type: 'link',
            icon: 'i-Financial',
            state: '/demo/financial'
        },
    ];
    defaultMenu: IMenuItem[] = [];



    // sets iconMenu as default;
    menuItems2 = new BehaviorSubject<IMenuItem[]>(this.defaultMenu2);
    menuItems3 = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    menuItems1 = new BehaviorSubject<IMenuItem[]>(this.defaultMenu1);
    menuItems4 = new BehaviorSubject<IMenuItem[]>(this.defaultMenu4);
    // navigation component has subscribed to this Observable
    menuItems$(id){
        // this.idvalue = id;
        if(id==3){
        return this.menuItems2.asObservable();   
    }else if(id==2){
        return this.menuItems3.asObservable(); 
    }else if(id==1){
        return this.menuItems1.asObservable();
    }else if(id==4){
        return this.menuItems4.asObservable();
    }
    }

    // You can customize this method to supply different menu for
    // different user type.
    // publishNavigationChange(menuType: string) {
    //   switch (userType) {
    //     case 'admin':
    //       this.menuItems.next(this.adminMenu);
    //       break;
    //     case 'user':
    //       this.menuItems.next(this.userMenu);
    //       break;
    //     default:
    //       this.menuItems.next(this.defaultMenu);
    //   }
    // }
}
