import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OthersRoutingModule } from './others-routing.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { RouterModule } from '@angular/router';
import { SignupComponent } from '../sessions/signup/signup.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TermsComponent } from './terms/terms.component';
import { EventPublishComponent } from './event-published/event-published.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    
    OthersRoutingModule
  ],
  declarations: [NotFoundComponent,SignupComponent,TermsComponent,EventPublishComponent]
})
export class OthersModule { }
