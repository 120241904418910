import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { AdminLayoutSidebarCompactComponent } from './shared/components/layouts/admin-layout-sidebar-compact/admin-layout-sidebar-compact.component';
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';
import { NotFoundComponent } from './views/others/not-found/not-found.component';

const adminRoutes: Routes = [
    {
      path: 'demo/dashboard',
      canActivate:[AuthGaurd],
      loadChildren: './views/dashboard/dashboard.module#DashboardModule'
    },
    // {
    //   path: 'demo/uikits',
    //   loadChildren: './views/ui-kits/ui-kits.module#UiKitsModule'
    // },
    // {
    //   path: 'demo/forms',
    //   loadChildren: './views/forms/forms.module#AppFormsModule'
    // },
    // {
    //   path: 'demo/invoice',
    //   loadChildren: './views/invoice/invoice.module#InvoiceModule'
    // },
    // {
    //   path: 'demo/inbox',
    //   loadChildren: './views/inbox/inbox.module#InboxModule'
    // },
    {
      path: 'demo/calendar',
      loadChildren: './views/calendar/calendar.module#CalendarAppModule'
    },
    // {
    //   path: 'demo/chat',
    //   loadChildren: './views/chat/chat.module#ChatModule'
    // },
    // {
    //   path: 'demo/tables',
    //   loadChildren: './views/data-tables/data-tables.module#DataTablesModule'
    // },
    {
      path: 'demo/superadmin',
      canActivate:[AuthGaurd],
      loadChildren: './views/superadmin/superadmin.module#SuperAdminModule'
    },
    {
      path:'demo/financial',
      canActivate:[AuthGaurd],
      loadChildren :'./views/accountfinance/accountfinance.module#AccountfinanceModule'
    },
    {
      path: 'demo/pages',
      canActivate:[AuthGaurd],
      loadChildren: './views/pages/pages.module#PagesModule'
    },
    // {
    //   path: 'demo/sample',
    //   loadChildren: './views/sample/sample.module#SampleModule' 
    // },
    {
      path: 'demo/horse',
      canActivate:[AuthGaurd],
      loadChildren: './views/horse/horse.module#HorseModule'
    },

    {
      path: 'demo/clubCo-ordinator',
      canActivate:[AuthGaurd],
      loadChildren: './views/clubCo-ordinator/clubco-ordinator.module#ClubcoOrdinatorModule'
    },

 {
      path: 'demo/club-list',
      canActivate:[AuthGaurd],
      loadChildren: './views/clubCo-ordinator/clubco-ordinator.module#ClubcoOrdinatorModule'
    },

    {
      path: 'demo/events',
      canActivate:[AuthGaurd],
      loadChildren: './views/eventsRegister/eventsRegister.module#EventsRegisterModule'
    },
    {
      path:'demo/user',
      canActivate:[AuthGaurd],
      loadChildren :'./views/user/user.module#UserModule'
    },
    {
      path:'demo/rider',
      canActivate:[AuthGaurd],
      loadChildren :'./views/rider/rider.module#RiderModule'
    },
    {
      path:'demo/defaultAddress',
      canActivate:[AuthGaurd],
      loadChildren: './views/defaultAddress/defaultAddress.module#DefaultAddressModule'
    },
    {
      path:'demo/termsAndConditions',
      canActivate:[AuthGaurd],
      loadChildren: './views/termsAndConditions/termsAndConditions.module#TermsAndConditionsModule'
    }

  ];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'demo/sessions/signin',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'demo/sessions',
        loadChildren: './views/sessions/sessions.module#SessionsModule'
      }
    ]
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'demo/others',
        loadChildren: './views/others/others.module#OthersModule'
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes
  },
  {
    path: '**',
    component : NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
