import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './event-published.component.html',
  styleUrls: ['./event-published.component.scss']
})
export class EventPublishComponent implements OnInit {
  eventListPublish:any;
  constructor(private service:CommonService) { }

  ngOnInit() {
    this.service.getEventListPublished().subscribe(res=>{
      console.log(res);
      this.eventListPublish = res;
    })


  }

}