import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationService, IMenuItem, IChildItem } from '../../../../services/navigation.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Utils } from '../../../../utils';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-sidebar-large',
  templateUrl: './sidebar-large.component.html',
  styleUrls: ['./sidebar-large.component.scss']
})
export class SidebarLargeComponent implements OnInit {
	width = '0px';
    selectedItem: IMenuItem;
	roleid;
	// nav: IMenuItem[];
	nav;
	constructor(
		public router: Router,
		private service:CommonService,
		public navService: NavigationService
	) { }

	ngOnInit() {
		var ab = this.service.localdata();
		this.roleid = ab[0].id_user_roles;	
		console.log(this.roleid+'Sidebar');
		this.service.getSidebar(this.roleid).subscribe(res=>{
			this.nav = res;
			this.setActiveFlag();
		})
		console.log(this.nav)
		// this.nav = [{   
		// 	        name: 'Dashboard',
		// 	        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
		// 	        type: 'link',
		// 	        icon: 'i-Bar-Chart',
		// 	        state: '/demo/dashboard/v1'
				  
		// 	    },
		// 	    {
		// 	        name: 'Event',
		// 	        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
		// 	        type: 'link',
		// 	        icon: 'i-Post-Sign-2-ways',
		// 	        state: '/demo/user/viewCalender'
		// 		}]
		// 		console.log(this.nav)
		if(this.roleid === 1 || this.roleid === 2){
			this.width = '0px';
		}else{
			this.width = '100px';
		}
		this.updateSidebar();
		// CLOSE SIDENAV ON ROUTE CHANGE
		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((routeChange) => {
				this.closeChildNav();
				if (Utils.isMobile()) {
					this.navService.sidebarState.sidenavOpen = false;
				}
			});

		// this.navService.menuItems$(this.roleid)
		// 	.subscribe((items) => {
		// 		console.log(items);
		// 		this.nav = items;
		// 		console.log(this.nav);
		// 		this.setActiveFlag();
		// 	});
	}

	selectItem(item) {
		this.navService.sidebarState.childnavOpen = true;
		this.selectedItem = item;
		this.setActiveMainItem(item);
	}
	closeChildNav() {
		this.navService.sidebarState.childnavOpen = false;
		this.setActiveFlag();
	}

	onClickChangeActiveFlag(item) {
		this.setActiveMainItem(item);
	}
	setActiveMainItem(item) {
		this.nav.forEach(item => {
			item.active = false;
		});
		item.active = true;
	}

	setActiveFlag() {
		if (window && window.location) {
            const activeRoute = window.location.hash || window.location.pathname;
			this.nav.forEach(item => {
				item.active = false;
				if (activeRoute.indexOf(item.state) !== -1) {
                    this.selectedItem = item;
					item.active = true;
				}
				if (item.sub) {
					item.sub.forEach(subItem => {
                        subItem.active = false;
						if (activeRoute.indexOf(subItem.state) !== -1) {
                            this.selectedItem = item;
                            item.active = true;
                        }
                        if (subItem.sub) {
                            subItem.sub.forEach(subChildItem => {
                                if (activeRoute.indexOf(subChildItem.state) !== -1) {
                                    this.selectedItem = item;
                                    item.active = true;
                                    subItem.active = true;
                                }
                            });
                        }
					});
				}
            });
		}
    }

	updateSidebar() {
		if (Utils.isMobile()) {
			this.navService.sidebarState.sidenavOpen = false;
			this.navService.sidebarState.childnavOpen = false;
		} else {
			this.navService.sidebarState.sidenavOpen = true;
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.updateSidebar();
    }

}
