import { Component, OnInit } from '@angular/core';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/common.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  animations: [SharedAnimations]
})
export class SignupComponent implements OnInit {
  passwordError=false;
  basicForm:FormGroup;
  step2Form:FormGroup;
  
  registerForm:FormGroup;
  riderForm:FormGroup;
  submittedError = false;
  radioprofile = 1;
  hideprofile = 0;
  newpwd:any;
  getRoles;
  sign = false;
  signform = true;
  repwd:any;
  constructor(private fb:FormBuilder,private service:CommonService,private router:Router) { 

//jQuery time


  }

  ngOnInit() {
    this.step2Form = this.fb.group({
      category: ['',[Validators.required]]
    });
    this.registerForm = this.fb.group({
      clubname:['',[Validators.required]],
      emailid:['',[Validators.required,Validators.email]],
      mobile:['',[Validators.required]],
      address:['',[Validators.required]],
    })
    this.riderForm = this.fb.group({
      firstName : ['',Validators.required],
      lastName : ['',Validators.required],
      email_id: ['', Validators.required],
      mobile : ['',Validators.required],
      address : ['',Validators.required],
      DOB : ['',Validators.required],
      gender : ['',Validators.required],
      weight : ['',Validators.required],
      efi_rider_id : ['',Validators.required],
      passport_no : ['',Validators.required]
  });
    this.basicForm = this.fb.group({
      username: ['',Validators.required],
      email: ['',Validators.required],
      password: ['',Validators.required],
      repassword: ['',Validators.required],
    });
    this.service.getRoles().subscribe(res=>{
      console.log(res);
      this.getRoles = res;
    })
  }
 
  newpassword()
  {
console.log(this.registerForm.value.password);

this.newpwd=this.registerForm.value.password
console.log(this.newpwd);
  }
  passwordchange(){
    // console.log("hii");
    var repwd=this.registerForm.value.repassword;
    console.log(repwd);
    console.log(this.registerForm.value.password);
if(this.registerForm.value.password==repwd)
{
// console.log("true");
  this.passwordError = false;
}else{
  this.passwordError = true;
  console.log("Error");
}

    // console.log(this.registerForm.value.password,this.registerForm.value.repassword)
    // if(this.registerForm.value.password == this.registerForm.value.repassword){
    //   this.passwordError = false;
    // }else if(this.registerForm.value.password != this.registerForm.value.repassword){
    //   this.passwordError = true;
    // }
  }

  radiocheck(id){
    console.log(this.basicForm.value);
    console.log(id,this.step2Form.value);
    this.radioprofile = id;
  }

  signup(){
    console.log(this.registerForm.value)
    if(this.registerForm.invalid  || this.passwordError){
      console.log("False");
      this.submittedError = true;
      // return;
    }else{
      console.log("True");
      this.sign = true;
      this.signform = false;
      this.submittedError = false;
      
      this.service.signup(this.registerForm.value).subscribe(res=>{
        console.log(res);
        localStorage.setItem('id',res[0]);
      })
    }
  }

  otpvalidation(otp){
    var ab = this.service.localdata();
    this.service.otpValid(otp,ab[0].id_users).subscribe(res=>{
      console.log(res);
      this.router.navigate(['/demo/sessions/signin']);
    })
  }

}
