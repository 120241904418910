import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';
import { HttpClientModule } from '@angular/common/http';
import { OthersModule } from './views/others/others.module';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import {CountDown} from "ng4-date-countdown-timer";
const config: SocketIoConfig = { url: 'https://booking.embassyridingschool.in', options: {transports:['websocket']} };
import { CountdownTimerModule } from 'ngx-countdown-timer';
import { CommonService } from './common.service';
import { SMSSendService } from './smssend.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
   
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
    // CountdownModule,
    CountdownTimerModule.forRoot(),
    // CountDown,
    AppRoutingModule,
    SocketIoModule.forRoot(config),
    OthersModule
  ],
  providers: [CommonService,SMSSendService],
  bootstrap: [AppComponent]
})
export class AppModule { }
