import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bootDash';
  constructor(){}
  ngOnInit(){
    // this.service.watchStorage().subscribe((data:string) => {
    //   console.log(data);
    //   alert("Data cannot be editable");
    //   // this will call whenever your localStorage data changes
    //   // use localStorage code here and set your data here for ngFor
    //   })
  }
}
